:root {
	--fontFamilyMonospace: "Courier";
}

* {
	box-sizing: border-box;
}

.simple .header {
	cursor: grab;
}

html,
body {
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
		Helvetica Neue, sans-serif;
	padding: 0px;
	margin: 0px;
	-webkit-font-smoothing: none;
	image-rendering: pixelated;
	height: 100%;
	background-image: linear-gradient(
			0deg,
			transparent 9%,
			rgba(255, 255, 255, 0.2) 10%,
			rgba(255, 255, 255, 0.2) 12%,
			transparent 13%,
			transparent 29%,
			rgba(255, 255, 255, 0) 30%,
			rgba(255, 255, 255, 0) 31%,
			transparent 32%,
			transparent 49%,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 0) 51%,
			transparent 52%,
			transparent 69%,
			rgba(255, 255, 255, 0) 70%,
			rgba(255, 255, 255, 0) 71%,
			transparent 72%,
			transparent 89%,
			rgba(255, 255, 255, 0) 90%,
			rgba(255, 255, 255, 0) 91%,
			transparent 92%,
			transparent
		),
		linear-gradient(
			90deg,
			transparent 9%,
			rgba(255, 255, 255, 0.2) 10%,
			rgba(255, 255, 255, 0.2) 12%,
			transparent 13%,
			transparent 29%,
			rgba(255, 255, 255, 0) 30%,
			rgba(255, 255, 255, 0) 31%,
			transparent 32%,
			transparent 49%,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 0) 51%,
			transparent 52%,
			transparent 69%,
			rgba(255, 255, 255, 0) 70%,
			rgba(255, 255, 255, 0) 71%,
			transparent 72%,
			transparent 89%,
			rgba(255, 255, 255, 0) 90%,
			rgba(255, 255, 255, 0) 91%,
			transparent 92%,
			transparent
		);
	background-size: 45px 45px;
	background-position: -3px 6px;
}

body:after {
	position: fixed;
	top: 0;
	left: 0;
	content: "";
	background: linear-gradient(180deg, #ddd6f3, #faaca8);
	width: 100%;
	height: 100%;
	z-index: -1;
	pointer-events: none;
	transform: translateZ(0);
}

twitter-widget,
iframe {
	-webkit-font-smoothing: auto;
	padding: 20px;
}

/*Maybe grid is better here*/
/*body:before {
  content: "";
  z-index: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  transform: translateZ(0);
}*/

h1,
h2,
nav {
	padding: 0px;
	margin: 0px;
}

code {
	background: #fafafa;
	padding: 0px 4px;
	border: 1px solid #ddd;
}

pre {
	line-height: 22px;
	background: #fafafa;
	border-radius: 3px;
	border: 1px solid #ddd;
	padding: 10px;
	overflow-x: scroll;
}

pre code {
	background: none;
	border: none;
}

blockquote {
	border-left: 2px dotted blue;
	margin-left: 10px;
	padding-left: 18px;
	padding-top: 1px;
	padding-bottom: 1px;
}

.highlight pre code {
	padding: 0;
}

nav {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 20px;
	top: 0;
	z-index: 2;
}

.category {
	font-family: var(--fontFamilyMonospace);
	text-decoration: none;
	color: white;
	font-weight: bolder;
	letter-spacing: 2px;
	font-size: 13px;
}

.icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	font-weight: 600;
	font-size: 15px;
	text-shadow: 1px 1px 0px black;
	color: white;
	max-width: 80px;
	text-align: center;
}

.icon img {
	height: 44px;
	width: auto;
	padding-bottom: 6px;
}

.main-grid {
	display: flex;
	padding: 10px 30px;
	justify-content: center;
}

.main-grid article {
	max-width: 360px;
	margin-left: 15px;
	margin-right: 15px;
}

article {
	background: #bfbfbf;
	border-color: #dfdfdf grey grey #dfdfdf;
	border-style: solid;
	border-width: 1px;
	white-space: pre-line;
	position: relative;
}

.header {
	background: linear-gradient(90deg, #f68084, #a6c0fe);
	color: white;
	padding: 3px 3px 3px 7px;
	font-size: 12px;
	border-bottom: 1px solid grey;
	font-weight: bold;
	letter-spacing: 0.4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.more-content {
	display: none;
}

.links,
.languages {
	display: flex;
	align-items: baseline;
	margin-bottom: 20px;
	cursor: pointer;
}

.links .icon:not(:first-child),
.languages .icon:not(:first-child) {
	margin-left: 25px;
}

.plain {
	color: inherit;
	text-decoration: none;
}

.header button {
	padding: 0;
	font-size: 11px;
	font-weight: bold;
	width: 16px;
	height: 16px;
	border-radius: 0;
	border: 1px solid #ddd;
	background: #f9f9f9;
	color: rgba(0, 0, 0, 0.9);
	position: relative;
	vertical-align: top;
	cursor: pointer;
	outline: 0;
}

.header button:hover {
	background: #eee;
}

.minimise span {
	position: absolute;
	bottom: 2px;
	width: 8px;
	left: 3px;
	height: 2px;
	background: black;
	transform: translateY(-0.5px);
}

.close span {
	display: block;
	transform: scale(0.8);
}

.close span:before,
.close span:after {
	transform: rotate(-45deg);
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	height: 2px;
	width: 14px;
	margin-left: -7px;
	background-color: black;
	margin-top: -1px;
}

.close span:after {
	transform: rotate(-135deg);
}

.buttons {
	display: flex;
}

.buttons > * {
	margin-left: 3px;
}

.expand span {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 10px;
	height: 10px;
	border: 1px solid;
	border-top: 2px solid;
}

.content {
	background: white;
	padding: 10px;
	border-top: 1px solid #e2e2e2;
	font-family: var(--fontFamilyMonospace);
	line-height: 18px;
	font-size: 15px;
	letter-spacing: 0.4px;
	position: relative;
}

.content h1 {
	letter-spacing: 0.6px;
	line-height: 19px;
	font-size: 18px;
	margin-bottom: 10px;
}

.skills-table {
	width: 100%;
	text-align: left;
}

.cover-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

p {
	white-space: pre-wrap;
}

span.read-more {
	font-weight: bold;
	display: block;
	margin-top: 10px;
}

.article-wrap {
	position: relative;
	z-index: 1;
}

article:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 5px;
	left: 5px;
	z-index: -1;
	background-image: radial-gradient(black 10%, transparent 0), radial-gradient(black 10%, transparent 0);
	background-position: 0 0, 3px 2px;
	background-size: 6px 6px;
	opacity: 0.5;
	background-color: rgba(0, 0, 0, 0.1);
}

.content img {
	width: 100%;
}

.title {
	text-decoration: none;
	color: inherit;
}

article {
	max-width: 450px;
}

article.closed {
	opacity: 0;
	pointer-events: none;
}

.meta {
	font-size: 12px;
	font-weight: bold;
	padding: 0px 2px;
	text-transform: lowercase;
	display: flex;
	background: #eee;
	color: #333;
}

.item {
	border-right: 1px solid #ddd;
	padding: 5px;
	text-decoration: none;
	color: inherit;
	letter-spacing: 0.2px;
	white-space: nowrap;
}

span.item {
	color: #333;
}

.item:last-child {
	border-right: 0;
}

.secondary-set {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.secondary-set article:first-child {
	margin-top: 25px;
}

.secondary-set article {
	margin-top: 20px;
}

.secondary-set article:nth-child(odd) {
	transform: translateX(calc(-25% + 10px));
}

.secondary-set article:nth-child(even) {
	transform: translateX(calc(25% - 10px));
}

.single-item {
	display: flex;
	justify-content: center;
	alignment-baseline: center;
	padding: 20px;
	padding-bottom: 100px;
}

.pagination {
	padding: 60px;
	font-size: 38px;
	display: flex;
	justify-content: space-between;
}

.pagination a {
	text-decoration: none;
	padding: 4px 12px;
	font-size: 22px;
	color: white;
	font-weight: bold;
	text-shadow: 1px 1px 0px black;
	font-family: var(--fontFamilyMonospace);
	letter-spacing: 1px;
	animation: openShut 1000ms linear infinite alternate;
	height: 38px;
	vertical-align: top;
}

@keyframes openShut {
	from {
		letter-spacing: 1px;
		font-size: 22px;
	}
	to {
		letter-spacing: 4px;
		font-size: 23px;
	}
}

@media only screen and (max-width: 850px) {
	.main-grid {
		flex-direction: column;
		align-items: center;
		padding-bottom: 0;
	}

	.single-item article {
		max-width: 100%;
	}

	.main-grid .article-wrap,
	.secondary-set article {
		margin-top: 25px;
	}

	.main-grid .article-wrap:nth-child(even) {
		transform: translateX(calc(-25% + 10px));
	}

	.main-grid .article-wrap:nth-child(odd) {
		transform: translateX(calc(25% - 10px));
	}
}

/*TODO: Clean up*/
@media only screen and (max-width: 720px) {
	.wrapper {
		padding: 20px;
	}

	.main-grid {
		padding: 0;
	}

	.pagination {
		padding: 30px 0 20px;
	}

	.icon {
		font-size: 12px;
		max-width: 40px;
	}
	
	.icon img {
		height: 34px;
		width: auto;
		padding-bottom: 6px;
	}

	nav {
		justify-content: center;
	}
	.links .icon:not(:first-child),
	.languages .icon{
		margin-left: 20px;
	}

	.wrapper .main-grid .article-wrap,
	.wrapper .secondary-set article {
		transform: none;
		width: 100%;
	}

	.wrapper .main-grid article,
	.wrapper .secondary-set article {
		max-width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
}

/* Weird safari hacks */
@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) and (stroke-color: transparent) {
		:root {
			--fontFamilyMonospace: "Courier New";
		}

		.content h1 {
			-webkit-font-smoothing: antialiased;
		}
	}
}